export default {
  tooltip: {
    trigger: 'axis',
    transitionDuration: 0,
    extraCssText: 'width: 180px',
    appendToBody: true,
    formatter: (params) => {
    }
  },
  legend: {
    data: [],
    top: '6%'
  },
  grid: {
    top: '10%',
    left: '4%',
    right: '6%',
    bottom: '10%',
    containLabel: true
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: [],
    axisLine: {
      lineStyle: {
        color: '#8d8d8d',
        width: 0.5
      }
    }
  },
  yAxis: [
    {
      type: 'value',
      position: 'left',
      axisLabel: {
        formatter: '{value}',
        show: true,
        color: '#8d8d8d'
      },
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      splitLine: {
        show: false
      }
    },
    {
      type: 'value',
      axisLabel: {
        formatter: '{value}',
        show: true,
        color: '#8d8d8d'
      },
      position: 'right',
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: '#e5e5e5'
        }
      }
    }
  ],
  series: [
    {
      name: '',
      type: 'line',
      areaStyle: {
        color: 'rgba(51, 102, 255, 0.1)'
      },
      smooth: true,
      yAxisIndex: 0,
      data: [],
      symbol: 'circle', // 设定为实心点
      symbolSize: 5, // 设定实心点的大小
      itemStyle: {
          color: 'rgba(51, 102, 255, 1)',
          lineStyle: {
            color: 'rgba(51, 102, 255, 1)',
            width: 3
          }
      }
    }
  ]
}
