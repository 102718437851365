<template>
  <div class="income-card">
    <m-ratio-box class="ratio-box" :ratio="83" :style="{'font-size': fontSize + 'px'}">
      <HomeTitle class="title" :title="'收入统计'" />
      <a-spin :spinning="spinning">
        <div class="income-item">
          <div class="income-item-inner" v-for="(item, index) in cardList" :key="index">
            <div class="item-inner-left">
              <div class="coin-icon" :style="{ backgroundColor: `${item.color}18` }">
                <GoldCoin class="icon" :style="{ color: item.color }" />
              </div>
              <div class="label">{{ item.label }}</div>
              <div class="value">
                <span class="moneyText" v-if="isMoney(item.valueProp)">{{ moneyText }}</span>
                <span class="valueValue">{{ numFormat(dataList[item.valueProp], 3, '') }}</span>
              </div>
            </div>
            <div class="ratio">
              <div
                class="icon"
                v-if="item.ratioProp"
                :style="{ color: +dataList[item.ratioProp] >= 0 ? '#FF8A8A' : '#64C8BC' }">
                <component
                  class="ratio-icon"
                  :is="+dataList[item.ratioProp] >= 0 ? 'RightTop' : 'RightBottom'"
                >
                </component>
              </div>
              <div
                class="ratio-value"
                v-if="item.ratioProp"
                :style="{ color: +dataList[item.ratioProp] >= 0 ? '#FF8A8A' : '#64C8BC' }">
                {{ dataList[item.ratioProp] || 0 }}%
              </div>
              <div v-else class="no-ratio"></div>
            </div>
          </div>
        </div>
      </a-spin>
    </m-ratio-box>
  </div>
</template>

<script>
import { summaryData } from '@/apiForManage/dashboard'
import HomeTitle from '../../components/homeTitle'
import { mapState } from 'vuex'
import autoFontSize from '@/mixins/autoFontSize'
import GoldCoin from '@/assets/icons/goldCoin.svg?inline'
import RightTop from '@/assets/icons/rightTop.svg?inline'
import RightBottom from '@/assets/icons/rightBottom.svg?inline'
import { numFormat } from '@/utils/dealNumber'
import mixRateAndMoneyMethod from '@/mixins/rateAndMoneyMethod'
export default {
  components: { HomeTitle, GoldCoin, RightTop, RightBottom },
  mixins: [autoFontSize, mixRateAndMoneyMethod],
  name: 'RealTimeCard',
  data () {
    return {
      dataList: {},
      spinning: false,
      cardList: [
        {
          valueProp: 'yesterdayRevenue',
          ratioProp: 'yesterdayRatio',
          color: '#61A4E4',
          label: '昨日收入'
        },
        {
          valueProp: 'sevenDaysRevenue',
          ratioProp: 'sevenDaysRatio',
          color: '#6554C0',
          label: '七日收入'
        },
        {
          valueProp: 'monthRevenue',
          ratioProp: 'monthRatio',
          color: '#FFA958',
          label: '本月收入'
        },
        {
          valueProp: 'sumRevenue',
          color: '#45B854',
          label: '总计收入'
        }
      ]
    }
  },
  computed: {
    ...mapState({
    })
  },
  watch: {
  },
  mounted () {
    this.getRealTimeData()
  },
  methods: {
    numFormat,
    async getRealTimeData () {
      this.spinning = true
      const resp = await summaryData()
      if (resp.code === 200) {
        this.dataList = resp.data
      }
      this.spinning = false
    }
  }
}
</script>

<style lang="less" scoped>
.income-card {
  background: #FFFFFF;
  box-shadow: @modelShadow;
  border: 1px solid #D9E1EC;
  // width: 33%;
  width: calc(34% - 12em / 14);

  border-radius: @mediumRadius;
  padding: calc(24em / 14);
  font-family: PingFang SC, PingFang SC;
  .ratio-box {
    align-items: center;
    .title {
      margin-left: calc(6em / 14);
    }
    ::v-deep {
      .ant-spin-nested-loading {
        height: 100%;
        position: relative;
        .ant-spin-container {
          width: 100%;
          height: 100%;
          padding: calc(14em / 14) 0;
          .income-item {
            width: 100%;
            height: 100%;
            flex-grow: 1;

            .income-item-inner {
              height: 25%;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              font-size: calc(14em / 14);
              border-bottom: 1px solid #eee;
              .item-inner-left {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 72%;
                .coin-icon {
                  height: calc(30em / 14);
                  width: calc(30em / 14);
                  border-radius: 50%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-right: calc(14em / 14);
                  .icon {
                    width: calc(20em / 14);
                    height: calc(20em / 14);
                  }
                }
                .label {
                  color: #5A607F;
                  font-weight: 500;
                  margin-right: calc(46em / 14);
                  font-size: calc(15em / 14);
                  line-height: calc(22em / 14);
                }
                .value {
                  color: #344563;
                  font-weight: 500;
                  width: 40%;
                  display: flex;
                  align-items: center;
                  .moneyText {
                    font-size: calc(14em / 14);
                  }
                  .valueValue {
                    font-size: calc(16em / 14);
                  }
                }
              }
              .ratio {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 25%;
                .icon {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
                .ratio-icon {
                  height: calc(14em / 14);
                  width: calc(14em / 14);
                }
                .ratio-value {
                  font-size: calc(14em / 14);
                  font-weight: 500;
                  margin-left: calc(4em / 14);
                }
                .no-ratio{
                  width: calc(44em / 14);
                  height: calc(2em / 14);
                  background-color: #E3E4E6;
                  border-radius: calc(3em / 14);
                  margin-left: calc(16em / 14);
                }
              }
            }
            .income-item-inner:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
}
</style>
