<template>
  <div class="data-indicator-container" :style="{'font-size': fontSize + 'px'}">
    <m-ratio-box class="ratio-box" :ratio="65">
      <Control
        v-model="rangePickerDate"
        @changeDate="changeDate"
        :keyValue="keyValue"
        :platIdList="query.platIdList"
        :dspIdList="query.dspIdList"
        @changeOption="changeOption"
        @changePlatIdList="changePlatIdList"
        @changeUserIdList="changeUserIdList"
      />
      <a-spin :spinning="spinning">
        <Chart :option="option" />
      </a-spin>
    </m-ratio-box>
  </div>
</template>

<script>
import mixDate from '@/mixins/initDate'
import Control from './control'
import { dashboardChart } from '@/apiForManage/dashboard'
import options from '../option'
import { numFormat } from '@/utils/dealNumber'
import { mapState } from 'vuex'
import Chart from '@/components/common/Chart'
import autoFontSize from '@/mixins/autoFontSize'
import { getRandomColors } from '@/data/colorList'
import mixRateAndMoneyMethod from '@/mixins/rateAndMoneyMethod'

export default {
  components: { Control, Chart },
  mixins: [mixDate, autoFontSize, mixRateAndMoneyMethod],
  data () {
    return {
      spinning: false,
      query: {
        dateEnd: '',
        dateStart: '',
        userIdList: [],
        platIdList: []
      },
      keyValue: 'advRequest',
      lengthable: [],
      list: [],
      options: {}
    }
  },
  watch: {
  },
  computed: {
    ...mapState({
    }),
    option () {
      const op = JSON.parse(JSON.stringify(options))
      op.tooltip.formatter = (params) => {
        let str = ''
        params.forEach((item, index) => {
          const temp = {}
          if (
            item.seriesName === '收益' ||
            item.seriesName === 'eCPM' ||
            item.seriesName === '预估消耗' ||
            item.seriesName === '利润' ||
            item.seriesName === '收入eCPM'
          ) {
            temp.value = `${ this.moneyText }` + numFormat(item.value, 3, '')
          } else if (
            item.seriesName === '广告填充率' ||
            item.seriesName === '点击率' ||
            item.seriesName === '竞胜率' ||
            item.seriesName === '利润率'
          ) {
            temp.value = item.value + '%'
          } else {
            temp.value = numFormat(item.value, 3, '')
          }
          str +=
            `<div style='width:100%;display:flex;justify-content:space-between'><span>` +
            item.marker +
            item.seriesName +
            ': </span>' +
            temp.value +
            '</div>'
        })
        return params[0].axisValue + '<br>' + str
      }
      const color = getRandomColors(1)[0]
      op.series[0].itemStyle.color = color
      op.series[0].lineStyle = {
        width: 3,
        color
      }
      op.series[0].areaStyle.color = {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0, // 从左到右，但不能从上到下，需要借助辅助系列
        y2: 1,
        colorStops: [
          {
            offset: 0,
            color // 0% 处的颜色
          },
          {
            offset: 1,
            color: '#fff' // 100% 处的颜色
          }
        ],
        globalCoord: false // 缺省为 false
      }
      op.xAxis.data = this.lable
      const field = this.list.filter((item) => item.field === this.keyValue)[0] || {}
      const { data = [], name } = field
      op.series[0].data = data
      op.series[0].name = name
      return op
    }
  },
  mounted () {
    const { endDate, startDate, end, start } = this.initDate(6, 0)
    this.rangePickerDate = [start, end]
    this.query.dateEnd = endDate
    this.query.dateStart = startDate
    this.getData()
  },
  methods: {
    changeDate (datas) {
      this.query.dateEnd = datas[1]
      this.query.dateStart = datas[0]
      this.getData()
    },
    async getData () {
      this.spinning = true
      const resp = await dashboardChart(this.query)
      const { lable = [], list = [] } = resp.data || []
      this.lable = lable
      this.list = list
      this.spinning = false
    },
    changeUserIdList (ids) {
      this.query.userIdList = ids
      this.getData()
    },
    changePlatIdList (ids) {
      this.query.platIdList = ids
      this.getData()
    },
    changeOption (event) {
      this.keyValue = event[0]
      this.getData()
    }
  }
}
</script>

<style lang="less" scoped>
.data-indicator-container {
  background: #fff;
  width: 100%;
  box-shadow: @modelShadow;
  border-radius: @mediumRadius;
  border: 1px solid #D9E1EC;
  padding: calc(24em / 14);
  margin-left: calc(12em / 14);
  ::v-deep {
    .ant-spin-nested-loading {
      position: relative;
      height: 100%;
      .ant-spin-container {
        width: 100%;
        height: 100%;
        .chart {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

}
</style>
