<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-16 15:20:57
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-04-18 18:45:00
 * @FilePath: /mediatom-web/src/viewsForManage/dashboard/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="dashboard-container">
    <RealTimeCard />
    <div class="home-main">
      <div class="home-main-top">
        <flowChartPosition />
        <bulletinBoard />
        <chartBudget />
      </div>
      <div class="home-main-bottom">
        <leftTrend/>
        <rightTrend />
      </div>
    </div>
  </div>
</template>

<script>
import RealTimeCard from './models/realTimeCard'
import leftTrend from './models/trafficTrend/leftTrend'
import rightTrend from './models/trafficTrend/rightTrend'
import bulletinBoard from './models/bulletinBoard'
import chartBudget from './models/chartBudget'
import flowChartPosition from './models/flowChartPosition'
import autoFontSize from '@/mixins/autoFontSize'

export default {
  name: 'Home',
  components: { RealTimeCard, chartBudget, flowChartPosition, bulletinBoard, leftTrend, rightTrend },
  mixins: [autoFontSize],
  data () {
    return {}
  },
  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  }
}
</script>

<style lang="less" scoped>
.dashboard-container {
  // min-width: 1280px;
  padding: calc(24em / 14);
  display: flex;
  flex-direction: column;
  background-color: #F5F6FA;
  .home-main {
    width: 100%;
    display: flex;
    flex-direction: column;
    .home-main-top,
    .home-main-bottom {
      margin-top: calc(24em / 14);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      flex: 1;
      position: relative;
    }
  }
}
</style>
