<template>
  <div class="control-container" :style="{'font-size': fontSize + 'px'}">
    <HomeTitle class="home_title" title="预算趋势" />
    <div class="control_item">
      <a-range-picker
        class="select_item date"
        format="MM.DD"
        :placeholder="['Start', 'End']"
        @change="ChangeDate"
        :value="modelDate"
        :allowClear="false"
        :disabledDate="disabledDate"
        size="small"
        ref="datepicker"
        :ranges="{
          今日: [initDate(0, 0).start, initDate(0, 0).end],
          昨日: [initDate(1, 1).start, initDate(1, 1).end],
          最近一周: [initDate(6, 0).start, initDate(6, 0).end],
          最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
        }"
      >
        <a-icon slot="suffixIcon" type="calendar" class="suffixIcon" />
      </a-range-picker>
      <m-select-more
        class="select_item"
        v-model="userIdList"
        :allData="adminUserList"
        :searchById="true"
        :hasIcon="false"
        :showId="false"
        label="账户"
        :showSelectedPart="true"
        :hasSearch="true"
        :hasPlease="false"
        width="450px"
        :showLabel="false"
        :canSelectAll="true"
        @change="handleChangeUser"
        v-if="role === 'SUPER_ADMIN'"
      />
      <m-select-more
        class="select_item"
        v-model="platIdLists"
        :allData="platList"
        :searchById="true"
        label="广告平台"
        :showLabel="false"
        :showSelectedPart="true"
        :canSelectAll="true"
        :hasPlease="false"
        @change="changePlat"
      />
      <a-select
        class="select_item"
        :value="keyValue1"
        @change="selectOption($event, 1)"
      >
        <a-select-option
          v-for="(child, index) in targetListRight"
          :key="index"
          :value="child.value"
        >
          {{ child.label }}
        </a-select-option>
      </a-select>
    </div>
  </div>
</template>

<script>
import mixDate from '@/mixins/initDate'
import { getDurations } from '@/utils/duration'
import { mapState } from 'vuex'
import mixGetList from '@/mixins/getListForManage'
import HomeTitle from '../../../components/homeTitle'
import autoFontSize from '@/mixins/autoFontSize'
import Index from '@/components/GlobalFooter/index.vue'
import { targetListRight } from '../list'

export default {
  mixins: [mixDate, mixGetList, autoFontSize],
  components: { HomeTitle },
  model: {
    prop: 'time',
    event: 'changeTime'
  },
  data () {
    return {
      modelDate: [],
      targetListRight,
      platIdLists: [],
      userIdList: []
    }
  },
  props: {
    time: {
      type: Array,
      default: () => []
    },
    keyValue: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0]
    }),
    keyValue1 () {
      return this.keyValue
    }
  },
  watch: {
    time: {
      handler (val) {
        this.modelDate = [...val]
      }
    }
  },
  async created () {
    this.getPlatList()
    this.role === 'SUPER_ADMIN' && await this.getAdminUserList()
  },
  mounted () {},
  methods: {
    // 初始化时间
    initTime () {
      const { endDate, startDate, end, start } = this.initDate(6, 0)
      this.rangePickerDate = [start, end]
      this.query.dateEnd = endDate
      this.query.dateStart = startDate
    },
    handleChangeUser (ids) {
      this.$emit('changeUserIdList', ids)
    },
    changePlat (ids) {
      this.$emit('changePlatIdList', ids)
    },
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    // 不可选时间
    disabledDate (current) {
      // 默认小于今天的时间 大于2023.10
      return (current && current < this.initDate(getDurations(), 1).start) || current >= this.initDate(0, 0).end
    },
    ChangeDate (date) {
      const start = date[0].format('YYYY-MM-DD')
      const end = date[1].format('YYYY-MM-DD')
      this.$emit('changeTime', date)
      this.$emit('changeDate', [start, end])
    },
    selectOption (val, index) {
      console.log(val, Index)
      this.$emit('changeOption', [val, index])
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../range.less';
::v-deep.control-container {
  .ant-calendar-picker-input.ant-input {
    padding-left: 0!important;
  }
  .ant-calendar-range-picker-separator {
    vertical-align: baseline;
  }
  .ant-calendar-range-picker-input {
    width: 40%;
  }
  .ant-calendar-picker.ant-calendar-picker-small {
    .ant-calendar-picker-input.ant-input:focus {
      box-shadow: none !important;
    }
  }
  .cust-select-multiple-container .select_box  {
    padding-left: calc(10em /14);
  }
  .ant-calendar-picker .ant-calendar-picker-input.ant-input.ant-input-sm {
    height: 36px!important;
  }
}
.control-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .home_title {
    margin-left: calc(6em / 14);
    width: 20%;
  }
  .control_item {
    margin-top: calc(-8em / 14);
    display: flex;
    justify-content: flex-end;
    min-width: 80%;
    .search_item {
      width: calc(150em / 14);
    }
    .select_item {
      width: calc(110em / 14);
      margin-left: calc(6em / 14) ;
    }
    .date{
      width: 32px;
    }
  }
}

</style>
